import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

function Category() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.category_list ? state?.allapi?.category_list : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_category({ category_id: e, page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.update_category({
        category_id: cateDetail?.id,
        name: cateDetail?.name,
        page: page,
      })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
  
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = () => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("name", cateDetailAdd?.name);

      dispatch(allapiAction.create_quiz_category(data));

      setcateDetailAdd({ name: "" });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.allcategory(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.allcategory(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.allcategory(e));

      return () => {};
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Category"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Category"}
            subHeading={`Total ${users_list?.total || 0} Category`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total:users_list?.total? users_list?.total:0
          }}
        />
      </div>
    </div>
  );
}

export default Category;
