import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import axios from "axios";

function Polls() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const dls_list = useSelector((state) =>
    state?.allapi?.polls_list ? state?.allapi?.polls_list : {}
  );
  const HomeList = useSelector((state) =>
    state?.allapi?.home_list ? state?.allapi?.home_list : []
  );
  const recent_match_list = useSelector((state) =>
    state?.allapi?.recent_match_list ? state?.allapi?.recent_match_list : []
  );
  console.log(recent_match_list);
  const [pages, setPage] = useState(1);
  //   const [HomeList, setHomeList] = useState([]);

  console.log(HomeList);

  const listdata = dls_list?.docs ? dls_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.searchDoucment({
          pageNumber: page,
          key: search,
        })
      );
    } else {
      dispatch(allapiAction.getpollslistsAdmin(page));
      dispatch(allapiAction.recentmatchList(page));
      dispatch(allapiAction.gethomeListAdmin());
      //   hendleTogetHome();

      //   http://apicricketchampion.in/webservices/homeList/token
      //   4f9e7f153f1bedd0765687d6ebf58062
    }

    return () => {};
  }, [location]);

  //   const hendleTogetHome = async () => {
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: "http://apicricketchampion.in/webservices/homeList/4f9e7f153f1bedd0765687d6ebf58062",
  //       headers: {},
  //     };

  //     const response = await axios.request(config);
  //     // setHomeList(response?.data?.data);
  //     // console.log(response?.data);
  //   };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userId, setuserId] = useState("");

  // view details

  const [viewDataBox, setviewDataBox] = useState(false);
  const [viewData, setviewData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusData, setstatusData] = useState({});

  console.log(viewData);

  const showstatusModal = (e) => {
    console.log(e);
    setstatusData(e);
    setstatusDataBox(true);
  };

  console.log(statusData);
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.updateDoucment({
    //     id: statusData?.id,
    //     panVerify: statusData?.panVerify,
    //     accountVerify: statusData?.accountVerify,
    //     aadharVerify: statusData?.aadharVerify,

    //     pageNumber: page,
    //   })

    // );

    statusData.pageNumber = page;

    dispatch(allapiAction.createDls(statusData));

    setstatusDataBox(false);
  };

  const showViewModal = (e) => {
    console.log(e);
    setviewData(e);
    setviewDataBox(true);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.deletePollsList({
        id: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToupdateStatus = (e, status) => {
    // updateStatusSlider

    dispatch(
      allapiAction.updateStatusSlider({
        id: e,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
  };

  const hendleTocreatePollsList = (e, status) => {
    // updateStatusSlider
    const filterMatch =
      recent_match_list &&
      recent_match_list?.filter((data) => {
        return data?.match_id == e;
      });
    const filnal = filterMatch[0];
    dispatch(
      allapiAction.createPollsList({
        match_id: filnal?.match_id,
        match_date: filnal?.match_date,
        match_time: filnal?.match_time,
        series: filnal?.series,
        series_id: filnal?.series_id,
        team_a_name: filnal?.team_a,
        team_b_name: filnal?.team_b,
        team_a_img: filnal?.team_a_img,
        team_b_img: filnal?.team_b_img,
        team_a_short: filnal?.team_a_short,
        team_b_short: filnal?.team_b_short,
        venue: filnal?.venue,
        count_a: 0,
        count_b: 0,
        tie: 0,
        pageNumber: page,
      })
    );
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (name, item) => {
    //     return (
    //       <>
    //         <div style={{ width: "150px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (name, item) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToupdateStatus(
    //                 item?.id,
    //                 item?.status == "active" ? "inactive" : "active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Matchs Id",
      dataIndex: "match_id",
      key: "match_id",
    },
    {
      title: "Match date",
      dataIndex: "match_date",
      key: "match_date",
    },
    {
      title: "Match Time",
      dataIndex: "match_time",
      key: "match_time",
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
    },
    {
      title: "team a",
      dataIndex: "team_a_name",
      key: "team_a_name",
    },
    {
      title: "team b",
      dataIndex: "team_b_name",
      key: "team_b_name",
    },
    // {
    //   title: "first inning team",
    //   dataIndex: "first_inning_team",
    //   key: "first_inning_team",
    // },
    // {
    //   title: "first inning score",
    //   dataIndex: "first_inning_score",
    //   key: "first_inning_score",
    // },
    // {
    //   title: "first inning wicket",
    //   dataIndex: "first_inning_wicket",
    //   key: "first_inning_wicket",
    // },
    // {
    //   title: "second inning target",
    //   dataIndex: "second_inning_target",
    //   key: "second_inning_target",
    // },
    // {
    //   title: "second inning over target",
    //   dataIndex: "second_inning_Over_target",
    //   key: "second_inning_Over_target",
    // },

    // {
    //   title: "Pan Card verify",
    //   dataIndex: "panVerify",
    //   key: "panVerify",
    // },
    // {
    //   title: "Account verify",
    //   dataIndex: "accountVerify",
    //   key: "accountVerify",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}

            <Tooltip title="Delete">
              <button
                className="delete-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip>
            {/* <Tooltip title="Update">
              <button
                className="filter-button ml-3 mt-3"
                onClick={() => showstatusModal(item)}
              >
                Update
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];
  const columns2 = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (name, item) => {
    //     return (
    //       <>
    //         <div style={{ width: "150px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (name, item) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToupdateStatus(
    //                 item?.id,
    //                 item?.status == "active" ? "inactive" : "active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
                <span
                  className="cursor-pointer"
                  onClick={() => navigate(`/users/contact/${item?.id}`)}
                >
                  <SvgIcon.contacts className="scale-icon edit-icon" />
                </span>
              </Tooltip> */}

            <Tooltip title="Create">
              <button
                className="success-button ml-3"
                onClick={() => hendleTocreatePollsList(item?.match_id)}
              >
                Create
              </button>
            </Tooltip>
            {/* <Tooltip title="Update">
              <button
                className="filter-button ml-3 mt-3"
                onClick={() => showstatusModal(item)}
              >
                Update
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
    {
      title: "Matchs Id",
      dataIndex: "match_id",
      key: "match_id",
    },
    {
      title: "Matchs",
      dataIndex: "matchs",
      key: "matchs",
    },
    {
      title: "Match date",
      dataIndex: "match_date",
      key: "match_date",
    },
    {
      title: "Match time",
      dataIndex: "match_time",
      key: "match_time",
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
    },
    {
      title: "team a",
      dataIndex: "team_a",
      key: "team_a",
    },
    {
      title: "team b",
      dataIndex: "team_b",
      key: "team_b",
    },
    {
      title: "Match type",
      dataIndex: "match_type",
      key: "match_type",
    },
    // {
    //   title: "first inning score",
    //   dataIndex: "first_inning_score",
    //   key: "first_inning_score",
    // },
    // {
    //   title: "first inning wicket",
    //   dataIndex: "first_inning_wicket",
    //   key: "first_inning_wicket",
    // },
    // {
    //   title: "second inning target",
    //   dataIndex: "second_inning_target",
    //   key: "second_inning_target",
    // },
    // {
    //   title: "second inning over target",
    //   dataIndex: "second_inning_Over_target",
    //   key: "second_inning_Over_target",
    // },

    // {
    //   title: "Pan Card verify",
    //   dataIndex: "panVerify",
    //   key: "panVerify",
    // },
    // {
    //   title: "Account verify",
    //   dataIndex: "accountVerify",
    //   key: "accountVerify",
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/drs?page=" + 1);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/drs?page=" + e);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [cateDetailAdd, setcateDetailAdd] = useState({});
  const [cateDetailImg, setcateDetailAddImg] = useState("");
  const [teamList, setteamList] = useState([]);

  //   console.log(cateDetailImg.target.files?.[0]);

  console.log(cateDetailAdd);
  console.log(cateDetailImg);
  //   console.log(cateDetailImg?.team_b);

  useEffect(() => {
    // first;
    // return () => {
    //   second;
    // };

    if (cateDetailImg) {
      const homeLsi = HomeList.find((obj) => obj.match_id == cateDetailImg);

      const teamlist = [{ name: homeLsi?.team_a }, { name: homeLsi?.team_b }];

      setteamList(teamlist);
    }
  }, [cateDetailImg]);

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;

    // console.log(name);
    // console.log(value);
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };
  const showModalAdd = (e) => {
    // setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = () => {
    if (!cateDetailAdd?.first_inning_score) {
      toast.error("please enter first inning score");
    } else {
      const data = new FormData();
      //   data.append("page", 1);
      //   data.append("type", cateDetailAdd?.type);
      //   data.append("link", cateDetailAdd?.link);
      //   data.append("image", cateDetailImg.target.files?.[0]);

      //   HomeList

      const homeLsi = HomeList.find((obj) => obj.match_id == cateDetailImg);

      const info = {
        second_inning_Over_target: cateDetailAdd?.second_inning_Over_target,
        second_inning_target: cateDetailAdd?.second_inning_target,
        over: cateDetailAdd?.over,
        first_inning_wicket: cateDetailAdd?.first_inning_wicket,
        first_inning_score: cateDetailAdd?.first_inning_score,
        first_inning_team: cateDetailAdd?.first_inning_team,

        team_a_image: homeLsi?.team_a_img,

        team_b_image: homeLsi?.team_b_img,
        team_a_short: homeLsi?.team_a_short,
        team_b_short: homeLsi?.team_b_short,

        team_b: homeLsi?.team_b,
        team_a: homeLsi?.team_a,
        matchs: homeLsi?.matchs,
        match_id: homeLsi?.match_id,
      };

      dispatch(allapiAction.createDls(info));

      setcateDetailAdd({
        second_inning_Over_target: "",
        second_inning_target: "",
        over: "",
        first_inning_wicket: "",
        first_inning_score: "",
        first_inning_team: "",
      });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="update  "
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>first inning score</Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <input
                autoComplete="new-off"
                className="form-control mt-5"
                placeholder="first inning score"
                name="first_inning_score"
                value={statusData?.first_inning_score}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>first inning wicket</Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <input
                autoComplete="new-off"
                className="form-control mt-5"
                placeholder="first inning wicket"
                name="first_inning_wicket"
                value={statusData?.first_inning_wicket}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>Over</Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <input
                autoComplete="new-off"
                className="form-control mt-5"
                placeholder="Over"
                name="over"
                value={statusData?.over}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>second inning target</Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <input
                autoComplete="new-off"
                className="form-control mt-5"
                placeholder="second inning  target"
                name="second_inning_target"
                value={statusData?.second_inning_target}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>second inning Over targets</Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <input
                autoComplete="new-off"
                className="form-control mt-5"
                placeholder="second inning Over target"
                name="second_inning_Over_target"
                value={statusData?.second_inning_Over_target}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            {/* <Col span={24}>transaction</Col>
            <Col span={24}>
              <Input
                name="transactionId"
                placeholder="enter transaction"
                value={statusData?.transactionId}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col> */}
          </Row>
        </div>
      </Modal>

      <Modal
        title="Detaills"
        centered
        open={viewDataBox}
        onOk={() => setviewDataBox(false)}
        onCancel={() => setviewDataBox(false)}
        width={1000}
      >
        <div>
          {/* viewData */}

          <h3>withdrawal details</h3>
          <Row>
            <Col span={6}>Name</Col>
            <Col span={6}>{viewData?.userDetails?.firstName}</Col>
            <Col span={6}>email</Col>
            <Col span={6}>{viewData?.userDetails?.email}</Col>
            <Col span={6}>mobile</Col>
            <Col span={6}>{viewData?.userDetails?.mobile}</Col>
            {/* <Col span={6}>Withdrawal in</Col>
            <Col span={6}>{viewData?.userDetails?.type}</Col>
            <Col span={6}>TransactionId</Col>
            <Col span={6}>{viewData?.userDetails?.transactionId}</Col> */}
          </Row>
          <hr />
          <h3>Account details</h3>

          <Row>
            <Col span={6}>bank Name</Col>
            <Col span={6}>{viewData?.bankName}</Col>
            <Col span={6}>Account Holder Name</Col>
            <Col span={6}>{viewData?.accountHolderName}</Col>
            <Col span={6}>Account Number</Col>
            <Col span={6}>{viewData?.accountNumber}</Col>
            <Col span={6}>Account Verify</Col>
            <Col span={6}>{viewData?.accountVerify}</Col>
            <Col span={6}>ifsc Code</Col>
            <Col span={6}>{viewData?.ifscCode}</Col>
          </Row>
          <hr />
          <h3>Aadhar card</h3>

          <Row>
            <Col span={6}>Aadhar Front </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharFront}
                />{" "}
              </div>
            </Col>
            <Col span={6}>Aadhar Back </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharBack}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Aadhar Number </Col>
            <Col span={6}>{viewData?.aadharNumber}</Col>
            <Col span={6}>Aadhar verify </Col>
            <Col span={6}>{viewData?.aadharVerify}</Col>
          </Row>
          <hr />
          <h3>Pan Card</h3>

          <Row>
            <Col span={6}>Pan card </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.panCardImg}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Name </Col>
            <Col span={6}>{viewData?.panName}</Col>
            <Col span={6}>Pan Card Number </Col>
            <Col span={6}>{viewData?.panNumber}</Col>
            <Col span={6}>Pan Card Verify </Col>
            <Col span={6}>{viewData?.panVerify}</Col>
          </Row>
          <hr />
          <h3>Upi</h3>

          <Row>
            <Col span={6}>Upi</Col>
            <Col span={6}>{viewData?.upiNumber}</Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add "
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        width={1000}
      >
        <div className="tableResponsive mt-10">
          <Table
            className={"table antd_table"}
            dataSource={recent_match_list}
            columns={columns2}
            pagination={{
              current: 1,
              pageSize: recent_match_list?.length,
              onChange(current, pageSize) {
                hendlePahegi(current);
              },
              hideOnSinglePage: true,
              total: dls_list?.total ? dls_list?.total : 0,
            }}
          />
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Polls"}
            subHeading={`Total ${dls_list?.total || 0} Polls`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
         
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: dls_list?.total ? dls_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Polls;
