import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

function Home() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allapiAction.dashboard({}));

    return () => { };
  }, []);

  // Function to get start and end dates for a given month
  function getStartAndEndDatesForMonth(year, month) {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0); // Get last day of the month
    return { startDate, endDate };
  }

  // Get current month and year
  // const currentDate = new Date();
  // const currentYear = currentDate.getFullYear();
  // const currentMonth = currentDate.getMonth();

  // // Get start and end dates for current month
  // const { startDate: currentMonthStartDate, endDate: currentMonthEndDate } =
  //  getStartAndEndDatesForMonth(currentYear, currentMonth);

  // // Determine the end date for Cycle 1 (15th day of the current month)
  // const cycle1EndDate = new Date(currentYear, currentMonth, 15);

  // // Determine the start date for Cycle 2 (16th day of the current month)
  // const cycle2StartDate = new Date(currentYear, currentMonth, 16);



  // // console.log(cycle1StartDate);
  // // console.log(cycle1EndDate);


  // console.log(currentMonthStartDate);
  // console.log(cycle1EndDate);

  // console.log(cycle2StartDate);
  // console.log(currentMonthEndDate);

  return (
    <div>
      <Row gutter={[20, 20]} type="flex">
        <Col lg={18} md={24} sm={24} xs={24}>
          <div className="dashboardBox p-10">
            {/* <CenteralHealthLine /> */}
            <Boxes />
          </div>
        </Col>
        {/* <Col lg={6} md={24} sm={24} xs={24}>
                    <div>
                        <Boxes />
                    </div>
                </Col> */}
      </Row>
    </div>
  );
}

export default Home;
