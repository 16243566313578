import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";

function Kyc() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const kyc_list = useSelector((state) =>
    state?.allapi?.kyc_list ? state?.allapi?.kyc_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = kyc_list?.docs ? kyc_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.searchDoucment({
          pageNumber: page,
          key: search,
        })
      );
    } else {
      dispatch(allapiAction.getDoucmentList(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userId, setuserId] = useState("");

  // view details

  const [viewDataBox, setviewDataBox] = useState(false);
  const [viewData, setviewData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusData, setstatusData] = useState({});

  console.log(viewData);

  const showstatusModal = (e) => {
    console.log(e);
    setstatusData(e);
    setstatusDataBox(true);
  };

  console.log(statusData);
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.updateDoucment({
        id: statusData?.id,
        panVerify: statusData?.panVerify,
        accountVerify: statusData?.accountVerify,
        aadharVerify: statusData?.aadharVerify,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showViewModal = (e) => {
    console.log(e);
    setviewData(e);
    setviewDataBox(true);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.deletebyadmin({
        id: userId,
        active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => <>{item?.userDetails?.name}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (name, item) => <>{item?.userDetails?.email}</>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (name, item) => <>{item?.userDetails?.mobile_no}</>,
    },
    {
      title: "Aadhar verify",
      dataIndex: "aadharVerify",
      key: "aadharVerify",
    },

    {
      title: "Pan Card verify",
      dataIndex: "panVerify",
      key: "panVerify",
    },
    {
      title: "Account verify",
      dataIndex: "accountVerify",
      key: "accountVerify",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}

            <Tooltip title="View">
              <button
                className="filter-button ml-3"
                onClick={() => showViewModal(item)}
              >
                View
              </button>
            </Tooltip>
            <Tooltip title="Update">
              <button
                className="filter-button ml-3"
                onClick={() => showstatusModal(item)}
              >
                Update
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/kyc?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/kyc?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="update status"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Verify Aadhar</Col>
            <Col span={24}>
              {" "}
              <select
                name="aadharVerify"
                className="form-select"
                value={statusData?.aadharVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>
            <Col span={24}>Verify Pancard</Col>
            <Col span={24}>
              {" "}
              <select
                name="panVerify"
                className="form-select"
                value={statusData?.panVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>

            <Col span={24}>Verify Account</Col>
            <Col span={24}>
              {" "}
              <select
                name="accountVerify"
                className="form-select"
                value={statusData?.accountVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>
            {/* <Col span={24}>transaction</Col>
            <Col span={24}>
              <Input
                name="transactionId"
                placeholder="enter transaction"
                value={statusData?.transactionId}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col> */}
          </Row>
        </div>
      </Modal>
      <Modal
        title="Detaills"
        centered
        open={viewDataBox}
        onOk={() => setviewDataBox(false)}
        onCancel={() => setviewDataBox(false)}
        width={1000}
      >
        <div>
          {/* viewData */}

          <h3>Withdrawal details</h3>
          <Row>
            <Col span={6}>Name</Col>
            <Col span={6}>{viewData?.userDetails?.name}</Col>
            <Col span={6}>email</Col>
            <Col span={6}>{viewData?.userDetails?.email}</Col>
            <Col span={6}>mobile</Col>
            <Col span={6}>{viewData?.userDetails?.mobile}</Col>
            {/* <Col span={6}>Withdrawal in</Col>
            <Col span={6}>{viewData?.userDetails?.type}</Col>
            <Col span={6}>TransactionId</Col>
            <Col span={6}>{viewData?.userDetails?.transactionId}</Col> */}
          </Row>
          <hr />
          <h3>Bank Account Details</h3>

          <Row>
            <Col span={6}>Bank Name</Col>
            <Col span={6}>{viewData?.bankName}</Col>
            <Col span={6}>Account Holder Name</Col>
            <Col span={6}>{viewData?.accountHolderName}</Col>
            <Col span={6}>Account Number</Col>
            <Col span={6}>{viewData?.accountNumber}</Col>
            <Col span={6}>Account Status </Col>
            <Col span={6}>{viewData?.accountVerify}</Col>
            <Col span={6}>IFSC Code</Col>
            <Col span={6}>{viewData?.ifscCode}</Col>
          </Row>
          <hr />
          <h3>Aadhar card</h3>

          <Row>
            <Col span={6}>Aadhar Front </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharFront}
                />{" "}
              </div>
            </Col>
            <Col span={6}>Aadhar Back </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharBack}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Aadhar Number </Col>
            <Col span={6}>{viewData?.aadharNumber}</Col>
            <Col span={6}>Aadhar verify </Col>
            <Col span={6}>{viewData?.aadharVerify}</Col>
          </Row>
          <hr />
          <h3>Pan Card</h3>

          <Row>
            <Col span={6}>Pan card </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.panCardImg}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Name </Col>
            <Col span={6}>{viewData?.panName}</Col>
            <Col span={6}>Pan Card Number </Col>
            <Col span={6}>{viewData?.panNumber}</Col>
            <Col span={6}>Pan Card Verify </Col>
            <Col span={6}>{viewData?.panVerify}</Col>
          </Row>
          <hr />
          <h3>Upi</h3>

          <Row>
            <Col span={6}>Upi</Col>
            <Col span={6}>{viewData?.upiNumber}</Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"KYC"}
            subHeading={`Total ${kyc_list?.total || 0} KYC`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: kyc_list?.total ? kyc_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Kyc;
