import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import Withdrawal from "../Component/Withdrawal/Withdrawal";
import Kyc from "../Component/Kyc/Kyc";
import SliderList from "../Component/SliderList/SliderList";
import Notifiction from "../Component/Notifiction/Notifiction";
import Setting from "../Component/Setting/Setting";
import Dls from "../Component/Dls/Dls";
import Drs from "../Component/Dls/Drs";
import Polls from "../Component/Poll/Polls";
import Tips from "../Component/Poll/Tips";
import Banner from "../Component/SliderList/Banner";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/slider" element={<SliderList />} />
          <Route path="/banner-popup" element={<Banner />} />
          <Route path="/dls" element={<Dls />} />
          <Route path="/drs" element={<Drs />} />
          <Route path="/notifiction" element={<Notifiction />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/tips" element={<Tips />} />
          {/* <Route path="/users/contact/:id" element={<Contact />} />
          <Route path="/category" element={<Category />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/question" element={<Question />} /> */}
          {/* <Route path="/faq-category" element={<FaqCategory />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
