import { Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../component/HeadingBack";

function Setting() {
  // sendNotification

  const dispatch = useDispatch();

  //   setting

  const [notificationData, setnotificationData] = useState({});

  const setting = useSelector((state) =>
    state?.allapi?.setting ? state?.allapi?.setting : {}
  );
  console.log(notificationData);

  useEffect(() => {
    dispatch(allapiAction.getPrizeSetting({}));

    return () => {};
  }, []);
  useEffect(() => {
    setnotificationData(setting);
  }, [setting]);

  const hendleToChange = (e) => {
    console.log(e);
    const { name, value } = e?.target;
    setnotificationData({ ...notificationData, [name]: value });
  };

  const hendleToSubmit = () => {
    // if (!notificationData?.title) {
    //   toast.error("please enter title");
    // } else if (!notificationData?.body) {
    //   toast.error("please enter body");
    // } else {

    dispatch(allapiAction.createPrizeSetting(notificationData));

    // setnotificationData({title:"",body:""})

    // }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Setting"}
            // subHeading={`Total ${kyc_list?.total || 0} Kyc`}
          />
        </div>
      </div>

      <Row gutter={[0, 16]}>
        <Col md={9} sm={24} xs={24} className="mh-100">
          {/* <LeftSection /> */}
        </Col>
        <Col
          md={24}
          sm={24}
          xs={24}
          className="flex justify-content-center align-items-center mh-100"
        >
          {" "}
          <div className="loginFormbox">
            <div className="form-group">
              <label>First Prize</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="firstPrize"
                  name="firstPrize"
                  value={notificationData?.firstPrize}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Second Prize</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="Second Prize"
                  name="secondPrize"
                  value={notificationData?.secondPrize}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Third Prize</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="thirdPrize"
                  name="thirdPrize"
                  value={notificationData?.thirdPrize}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Fourth Prize</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="fourthPrize"
                  name="fourthPrize"
                  value={notificationData?.fourthPrize}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Refer Amount</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="referAmount"
                  name="referAmount"
                  value={notificationData?.referAmount}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Telegram</label>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="enter telegram"
                  name="telegram"
                  value={notificationData?.telegram}
                  // onChange={handleChange}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Whatsapp Number</label>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="enter whatsapp number"
                  name="whatsappNumber"
                  value={notificationData?.whatsappNumber}
                  // onChange={handleChange}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>

            <button
              type="button"
              className="gradient-button w-100"
              onClick={() => hendleToSubmit()}
            >
              Update
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Setting;
