import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import * as moment from "moment";
import { Button, Modal, Space } from "antd";

function Withdrawal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const withdrawal_list = useSelector((state) =>
    state?.allapi?.withdrawal_list ? state?.allapi?.withdrawal_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = withdrawal_list?.docs ? withdrawal_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.withdrawalListSearchByAdmin({
          pageNumber: page,
          key: search,
        })
      );
    } else {
      dispatch(allapiAction.withdrawalListAdmin(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userId, setuserId] = useState("");

  // view details

  const [viewDataBox, setviewDataBox] = useState(false);
  const [viewData, setviewData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusData, setstatusData] = useState({});

  const showstatusModal = (e) => {
    console.log(e);
    setstatusData(e);
    setstatusDataBox(true);
  };

  console.log(statusData);
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.withdrawalListUpdateByAdmin({
        id: statusData?.id,
        status: statusData?.status,
        transactionId: statusData?.transactionId,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showViewModal = (e) => {
    console.log(e);
    setviewData(e);
    setviewDataBox(true);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.deletebyadmin({
        id: userId,
        active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "TransactionId",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Withdrawal in",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (name, item) => {
        return (
          <>
            {item?.createdAt &&
              moment(item?.createdAt).format("DD-MMM-YYYY hh:mm A")}
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}

            <Tooltip title="View">
              <button
                className="filter-button ml-3"
                onClick={() => showViewModal(item)}
              >
                View
              </button>
            </Tooltip>
            <Tooltip title="Update">
              <button
                className="filter-button ml-3"
                onClick={() => showstatusModal(item)}
              >
                Update
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/withdrawal?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/withdrawal?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="update status"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Status</Col>
            <Col span={24}>
              {" "}
              <select
                name="status"
                className="form-select"
                value={statusData?.status}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Success"}>Success</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>
            <Col span={24}>transaction</Col>
            <Col span={24}>
              <Input
                name="transactionId"
                placeholder="enter transaction"
                value={statusData?.transactionId}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Detaills"
        centered
        open={viewDataBox}
        onOk={() => setviewDataBox(false)}
        onCancel={() => setviewDataBox(false)}
        width={1000}
      >
        <div>
          {/* viewData */}

          <h3>Withdrawal Details</h3>
          <Row>
            <Col span={6}>Amount</Col>
            <Col span={6}>{viewData?.amount}</Col>
            <Col span={6}>Status</Col>
            <Col span={6}>{viewData?.status}</Col>
            <Col span={6}>Title</Col>
            <Col span={6}>{viewData?.title}</Col>
            <Col span={6}>Withdrawal in</Col>
            <Col span={6}>{viewData?.type}</Col>
            <Col span={6}>TransactionId</Col>
            <Col span={6}>{viewData?.transactionId}</Col>
          </Row>
          <hr />
          <h3>Account details</h3>

          <Row>
            <Col span={6}>Bank Name</Col>
            <Col span={6}>{viewData?.kycDetails?.bankName}</Col>
            <Col span={6}>Account Holder Name</Col>
            <Col span={6}>{viewData?.kycDetails?.accountHolderName}</Col>
            <Col span={6}>Account Number</Col>
            <Col span={6}>{viewData?.kycDetails?.accountNumber}</Col>
            <Col span={6}>Account Verify</Col>
            <Col span={6}>{viewData?.kycDetails?.accountVerify}</Col>
            <Col span={6}>IFSC Code</Col>
            <Col span={6}>{viewData?.kycDetails?.ifscCode}</Col>
          </Row>
          <hr />
          <h3>Upi</h3>

          <Row>
            <Col span={6}>Upi</Col>
            <Col span={6}>{viewData?.kycDetails?.upiNumber}</Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Withdrawal"}
            subHeading={`Total ${withdrawal_list?.total || 0} Withdrawal`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: withdrawal_list?.total ? withdrawal_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Withdrawal;
