import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import { db, storage } from "../../Firebase";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import firebase from "firebase";
import * as moment from "moment";
function SliderList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const [pages, setPage] = useState(1);
  const [protectProfile, setprotectProfile] = useState(false);
  const [comments, setComments] = useState([]);
  const listdata = comments ? comments : [];

  useEffect(() => {
    db.collection("slider")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });

        setComments(oderdetails);
      });

    db.collection("sliderActive")
      .doc("YhK4d1g9RfYJ5aZFw4Ik")
      .onSnapshot((snapshot) => {
        setprotectProfile(snapshot?.data()?.sliderActive);
      });
  }, []);

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.searchDoucment({
          pageNumber: page,
          key: search,
        })
      );
    } else {
      dispatch(allapiAction.getsliderList(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userId, setuserId] = useState("");

  // view details

  const [viewDataBox, setviewDataBox] = useState(false);
  const [viewData, setviewData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusData, setstatusData] = useState({});

  console.log(viewData);

  const showstatusModal = (e) => {
    console.log(e);
    setstatusData(e);
    setstatusDataBox(true);
  };

  console.log(statusData);
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.updateDoucment({
        id: statusData?.id,
        panVerify: statusData?.panVerify,
        accountVerify: statusData?.accountVerify,
        aadharVerify: statusData?.aadharVerify,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showViewModal = (e) => {
    console.log(e);
    setviewData(e);
    setviewDataBox(true);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    // dispatch(
    //   allapiAction.deleteSlider({
    //     id: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    const res = await db.collection("slider").doc(userId).delete();
    toast.success("deleted ");
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToupdateStatus = (e, status) => {
    // updateStatusSlider

    dispatch(
      allapiAction.updateStatusSlider({
        id: e,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (name, item) => {
        return (
          <>
            <div style={{ width: "150px" }}>
              <img style={{ width: "100%" }} src={item?.image} alt="" />
            </div>
          </>
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (name, item) => {
    //     return (
    //       <>
    //          <Tooltip title={item?.status}>
    //           <button
    //             className={item?.status == "active"?"success-button ml-3":"delete-button ml-3"}
    //             onClick={() => hendleToupdateStatus(item?.id , item?.status == "active" ? "inactive" :"active")}
    //           >
    //            { item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (name, item) => {
        // const date = new Date(item?.createdAt?.seconds  * 1000);
        return (
          <>
            {item?.createdAt &&
              moment(item?.createdAt?.seconds * 1000).format(
                "DD-MMM-YYYY hh:mm A"
              )}
          </>
        );
      },
    },
    // {
    //   title: "Slider Type",
    //   dataIndex: "type",
    //   key: "type",
    // },

    // {
    //   title: "Pan Card verify",
    //   dataIndex: "panVerify",
    //   key: "panVerify",
    // },
    // {
    //   title: "Account verify",
    //   dataIndex: "accountVerify",
    //   key: "accountVerify",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}

            <Tooltip title="Delete">
              <button
                className="delete-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip>
            {/* <Tooltip title="Update">
              <button
                className="filter-button ml-3"
                onClick={() => showstatusModal(item)}
              >
                Update
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/kyc?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/kyc?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [cateDetailAdd, setcateDetailAdd] = useState({});
  const [cateDetailImg, setcateDetailAddImg] = useState({});

  //   console.log(cateDetailImg.target.files?.[0]);

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;

    // console.log(name);
    // console.log(value);
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };
  const showModalAdd = (e) => {
    // setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = () => {
    if (!cateDetailAdd?.link) {
      toast.error("please enter link");
    } else {
      if (cateDetailImg.target.files?.[0] !== null) {
        const ref = storage.ref(
          `/slider/gallery/${cateDetailImg.target.files?.[0].name}`
        );
        const uploadTask = ref.put(cateDetailImg.target.files?.[0]);
        const oderDone = uploadTask.on(
          "state_changed",
          console.log,
          console.error,
          () => {
            ref.getDownloadURL().then((url) => {
              const abc = db.collection("slider").add({
                image: url,
                link: cateDetailAdd?.link,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              });

              toast.success("Image successfully Add");
              // reset();
              setAddModalOpen(false);
              setcateDetailAdd({ name: "" });
            });
          }
        );
      } else {
      }
      // const data = new FormData();
      // //   data.append("page", 1);
      // data.append("type", cateDetailAdd?.type);
      // data.append("link", cateDetailAdd?.link);
      // data.append("image", cateDetailImg.target.files?.[0]);

      // dispatch(allapiAction.createSlider(data));

      // dispatch(adminAction.getRoleList('dhsg'))

      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="update status"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Verify Aadhar</Col>
            <Col span={24}>
              {" "}
              <select
                name="aadharVerify"
                className="form-select"
                value={statusData?.aadharVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>
            <Col span={24}>Verify Pancard</Col>
            <Col span={24}>
              {" "}
              <select
                name="panVerify"
                className="form-select"
                value={statusData?.panVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>

            <Col span={24}>Verify Account</Col>
            <Col span={24}>
              {" "}
              <select
                name="accountVerify"
                className="form-select"
                value={statusData?.accountVerify}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value={"Verified"}>Verified</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Reject"}>Reject</option>
              </select>
            </Col>
            {/* <Col span={24}>transaction</Col>
            <Col span={24}>
              <Input
                name="transactionId"
                placeholder="enter transaction"
                value={statusData?.transactionId}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col> */}
          </Row>
        </div>
      </Modal>
      <Modal
        title="Detaills"
        centered
        open={viewDataBox}
        onOk={() => setviewDataBox(false)}
        onCancel={() => setviewDataBox(false)}
        width={1000}
      >
        <div>
          {/* viewData */}

          <h3>withdrawal details</h3>
          <Row>
            <Col span={6}>Name</Col>
            <Col span={6}>{viewData?.userDetails?.firstName}</Col>
            <Col span={6}>email</Col>
            <Col span={6}>{viewData?.userDetails?.email}</Col>
            <Col span={6}>mobile</Col>
            <Col span={6}>{viewData?.userDetails?.mobile}</Col>
            {/* <Col span={6}>Withdrawal in</Col>
            <Col span={6}>{viewData?.userDetails?.type}</Col>
            <Col span={6}>TransactionId</Col>
            <Col span={6}>{viewData?.userDetails?.transactionId}</Col> */}
          </Row>
          <hr />
          <h3>Account details</h3>

          <Row>
            <Col span={6}>bank Name</Col>
            <Col span={6}>{viewData?.bankName}</Col>
            <Col span={6}>Account Holder Name</Col>
            <Col span={6}>{viewData?.accountHolderName}</Col>
            <Col span={6}>Account Number</Col>
            <Col span={6}>{viewData?.accountNumber}</Col>
            <Col span={6}>Account Verify</Col>
            <Col span={6}>{viewData?.accountVerify}</Col>
            <Col span={6}>ifsc Code</Col>
            <Col span={6}>{viewData?.ifscCode}</Col>
          </Row>
          <hr />
          <h3>Aadhar card</h3>

          <Row>
            <Col span={6}>Aadhar Front </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharFront}
                />{" "}
              </div>
            </Col>
            <Col span={6}>Aadhar Back </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.aadharBack}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Aadhar Number </Col>
            <Col span={6}>{viewData?.aadharNumber}</Col>
            <Col span={6}>Aadhar verify </Col>
            <Col span={6}>{viewData?.aadharVerify}</Col>
          </Row>
          <hr />
          <h3>Pan Card</h3>

          <Row>
            <Col span={6}>Pan card </Col>
            <Col span={6}>
              {" "}
              <div style={{ width: "200px" }}>
                <img
                  style={{ width: "100%" }}
                  src={URL?.API_BASE_URL + viewData?.panCardImg}
                />{" "}
              </div>
            </Col>

            <Col span={6}>Name </Col>
            <Col span={6}>{viewData?.panName}</Col>
            <Col span={6}>Pan Card Number </Col>
            <Col span={6}>{viewData?.panNumber}</Col>
            <Col span={6}>Pan Card Verify </Col>
            <Col span={6}>{viewData?.panVerify}</Col>
          </Row>
          <hr />
          <h3>Upi</h3>

          <Row>
            <Col span={6}>Upi</Col>
            <Col span={6}>{viewData?.upiNumber}</Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Slider"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="image"
            type="file"
            accept="image/*"
            // value={cateDetailAdd?.name}
            onChange={(e) => {
              setcateDetailAddImg(e);
            }}
          />
          {/* <input type="file" name="image" id="image" accept="image/*"></input> */}
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-5"
            placeholder="Link"
            name="link"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <select
            autoComplete="new-off"
            className="form-control mt-5"
            placeholder="name"
            name="type"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}>select type</option>
            <option value={"banner"}>Banner</option>
            <option value={"slider"}>Slider</option>
          </select>
        </Col> */}
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Slider"}
            subHeading={`Total ${listdata?.length || 0} Slider`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
         
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 100,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: slider_list?.total ? slider_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default SliderList;
