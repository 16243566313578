import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  WITHDRAWL_LIST,
  KYC_LIST,
  SLIDER_LIST,
  SETTING,
  DLS_LIST,
  DRS_LIST,
  HOME_LIST,
  RECENT_MATCH_LISTS,
  POLLS_LISTS,
} from "../common/constant";

const initialState = {
  loading: false,

  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: {},
  quiz_list: {},
  question_list: {},
  deshboard: {},
  withdrawal_list: {},
  kyc_list: {},
  slider_list: {},
  setting: {},
  dls_list: {},
  drs_list: {},

  faqs_list: [],
  home_list: [],
  recent_match_list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case WITHDRAWL_LIST:
      return { ...state, withdrawal_list: action.payload };
    case KYC_LIST:
      return { ...state, kyc_list: action.payload };
    case SLIDER_LIST:
      return { ...state, slider_list: action.payload };
    case SETTING:
      return { ...state, setting: action.payload };
    case DLS_LIST:
      return { ...state, dls_list: action.payload };
    case DRS_LIST:
      return { ...state, drs_list: action.payload };
    case HOME_LIST:
      return { ...state, home_list: action.payload };
    case RECENT_MATCH_LISTS:
      return { ...state, recent_match_list: action.payload };
    case POLLS_LISTS:
      return { ...state, polls_list: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
