import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../component/HeadingBack";

function Notifiction() {
  // sendNotification

  const dispatch = useDispatch()

  const [notificationData, setnotificationData] = useState({});
  const [cateDetailImg, setcateDetailAddImg] = useState([]);
  const hendleToChange = (e) => {
    console.log(e);
    const { name, value } = e?.target;
    setnotificationData({ ...notificationData, [name]: value });
  };

  console.log(cateDetailImg);
  const hendleToSubmit = () => {
    if (!notificationData?.title) {
      toast.error("please enter title");
    } else if (!notificationData?.body) {
      toast.error("please enter body");
    } else {

      const data1 = new FormData();

      data1.append("title", notificationData?.title);
      data1.append("body", notificationData?.body);

      if (cateDetailImg) {
        data1.append("image", cateDetailImg);

      }


      dispatch(allapiAction.sendNotification(data1));

      setnotificationData({ title: "", body: "" })
      setcateDetailAddImg(null)
      document.getElementById('imageInput').value = '';
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Notification"}
          // subHeading={`Total ${kyc_list?.total || 0} Kyc`}
          />
        </div>
      </div>

      <Row gutter={[0, 16]}>
        <Col md={9} sm={24} xs={24} className="mh-100">
          {/* <LeftSection /> */}
        </Col>
        <Col
          md={24}
          sm={24}
          xs={24}
          className="flex justify-content-center align-items-center mh-100"
        >
          {" "}
          <div className="loginFormbox">
            <div className="form-group">
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="title"
                  name="title"
                  value={notificationData?.title}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-group mb-20">
                <Input
                  type="text"
                  className="form-control ant-email"
                  autoComplete="new-off"
                  placeholder="enter body"
                  name="body"
                  value={notificationData?.body}

                  // onChange={handleChange}
                  onChange={(e) => {
                    hendleToChange(e);
                  }}
                />
              </div>
              <Col lg={24} md={24} sm={24} xs={24}>
                <input
                  autoComplete="new-off"
                  className="form-control"
                  placeholder="name"
                  name="image"
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  // value={cateDetailAdd?.name}
                  onChange={(e) => {
                    setcateDetailAddImg(e.target.files[0]);
                  }}
                />
                {/* <input type="file" name="image" id="image" accept="image/*"></input> */}
              </Col>
            </div>

            <button
              type="button"
              className="gradient-button w-100"
              onClick={() => hendleToSubmit()}
            >
              Send Notification
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Notifiction;
